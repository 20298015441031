<template>
	<div class="total drag" v-draw id="CfbCommonSetting">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_常用设置</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				锅炉基础参数设置
			</div>
		</div>
		<!-- 主体内容 -->
		<div class="context">
			<!-- <div class="button">
				<div class="button_words">参数保存与恢复</div>
			</div> -->
			<div class="table flex">
				<div class="one">
					<div class="left bottom flex">
						<div class="column1">运行汽温</div>
						<div class="column2"
						@click="toIpt(infoList.MCSALARM.WDZB,'运行汽温','WDZB','MCSALARM')">
							{{infoList.MCSALARM.WDZB}}
						</div>
					</div>
					<div class="left bottom flex">
						<div class="column1">运行流量</div>
						<div class="column2"
						@click="toIpt(infoList.MCSALARM.LLZB,'运行流量','LLZB','MCSALARM')">
							{{infoList.MCSALARM.LLZB}}
						</div>
					</div>
					<div class="left bottom flex">
						<div class="column1">锅炉状态</div>
						<div class="column3">
						    {{infoList.MCSALARM.GLYX ? "运行" : "停炉"}}
						</div>
					</div>
					<div class="left flex">
						<div class="column1">燃煤热值</div>
						<div class="column2"
						@click="toIpt(infoList.MCSRSXL.RZ1,'燃煤热值','RZ1','MCSRSXL')">
							{{infoList.MCSRSXL.RZ1}}
						</div>
					</div>
				</div>
				<div class="two">
					<div class="flex">
						<div class="column1">设计负荷</div>
						<div class="column2"
						@click="toIpt(infoList.MCSGEIMEI.SJGLFH,'设计负荷','SJGLFH','MCSGEIMEI')">
							{{infoList.MCSGEIMEI.SJGLFH}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">设计汽压</div>
						<div class="column2"
						@click="toIpt(infoList.MCSGEIMEI.SJZQYL,'设计汽压','SJZQYL','MCSGEIMEI')">
							{{infoList.MCSGEIMEI.SJZQYL}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">设计汽温</div>
						<div class="column2"
						@click="toIpt(infoList.MCSGEIMEI.SJZQWD,'设计汽温','SJZQWD','MCSGEIMEI')">
							{{infoList.MCSGEIMEI.SJZQWD}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">设计煤量</div>
						<div class="column2"
						@click="toIpt(infoList.MCSGEIMEI.GLSJML,'设计煤量','GLSJML','MCSGEIMEI')">
							{{infoList.MCSGEIMEI.GLSJML}}
						</div>
					</div>
				</div>
				<div class="three">
					<div class="right bottom flex">
						<div class="column1">一次风机电压</div>
						<div class="column2"
						@click="toIpt(infoList.MCSRSXL.U_YCFJ,'一次风机电压','U_YCFJ','MCSRSXL')">
							{{infoList.MCSRSXL.U_YCFJ}}
						</div>
					</div>
					<div class="right bottom flex">
						<div class="column1">二次风机电压</div>
						<div class="column2"
						@click="toIpt(infoList.MCSRSXL.U_ECFJ,'二次风机电压','U_ECFJ','MCSRSXL')">
							{{infoList.MCSRSXL.U_ECFJ}}
						</div>
					</div>
					<div class="right bottom flex">
						<div class="column1">引风机电压</div>
						<div class="column2"
						@click="toIpt(infoList.MCSRSXL.U_YFJ,'引风机电压','U_YFJ','MCSRSXL')">
							{{infoList.MCSRSXL.U_YFJ}}
						</div>
					</div>
					<div class="right flex">
						<div class="column1">给煤机股数</div>
						<div class="column2"
						@click="toIpt(infoList.MCSGEIMEI.GMNO,'给煤机股数','GMNO','MCSGEIMEI')">
							{{infoList.MCSGEIMEI.GMNO}}
						</div>
					</div>
				</div>
			</div>
		</div>
	    <inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus = 'isHshow=false' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
		<Firstcontrol 
		:infoList = 'infoList' 
		:isIndex = 'isIndex'
		:historyname = 'Firstcontrolname'
		:titname = 'Firstcontroltitname'
		:node = 'Firstcontrolnode'
		v-if="isFshow"
		@sendStatus = 'isFshow=false'></Firstcontrol>
		<ParameterYh v-if="isComShow"  :infoList = 'infoList' @sendStatus = 'isshowfase'></ParameterYh>
		<Manual
		  :key="isIndex"
		  v-if="isMshow"
		  @sendStatus="isMshow = false"
		  :historyname="Manualname"
		  :node="Manualnode"
		  :Lkname="ManualAname"
		  :titname="Manualtitname"
		  :infoList="infoList">
		</Manual>
		</div>
	</template>
	
	<script>	
		import inputVal from "@/components/inputVal.vue"; //输入框组件
		import Historical from "@/components/Historical.vue" //历史趋势
		import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
		import Rsf from "@/components/RSF.vue"; //阮伺服组件
		import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
		import Manual from "@/components/Manual.vue"; //手操器组件
		export default {
		    name: "CfbCommonSetting",
		    props:{
	        infoList:{
	            type:Object,
	            default: () =>{
	              return {}// 默认值
	            } 
	        }
	    },
		 components:{inputVal, Historical,Firstcontrol,Rsf,ParameterYh,Manual},
		    data: () => {
		  return {
		      Manualname: "",
		      Manualnode: "",
		      ManualAname: "",
		      Manualtitname: "",
		      isComShow:false,
		      isHshow:false,
		      isMshow:false,
		      isRshow:false,
		      historyname:'',
		      node:'',
		      Lkname:'',
		      isIndex:'',
		      Firstcontrolname:'',
		      Firstcontroltitname:'',
		      Firstcontrolnode:'',
		      isFshow:false,
		      Rsfname:'',
		      Rsfnode:'',
		      Rsftitname:'',
		      projectData:'',
		      grouptime:null,
		      spotArr:[],
		      authInfo:[],
		      UserInfo:[],
		  }
		        
		 },
		   watch: {
	    infoList: {
	      handler(n, o) {
	        this.infoList = n;
			console.log(this.infoList)
	      },
	      deep: true, // 深度监听父组件传过来对象变化
	    },
	  },
		  created(){
		    this.projectData = JSON.parse(localStorage.getItem("deviceType"))
		    this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		    this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			
		  },
		  methods:{
		      // 打开先控多窗口
		  toDetail(key,name,nodename,Aname,titname){
		   if(!this.authInfo[1]){
		    let str = this.authInfo[0]
		    // 判断是否为调试还是查看权限
		    if (str.charAt(str.length-1) == "r") {
		     this.$message.error('暂无调试权限');
		    }
		   }else{
		    let pathname=""
		    switch(key){
		     case 1:
		     pathname = 'firstcontol'
		     break
		     case 2:
		     pathname = 'Cfbkfirstcontol'
		     break
		     case 3:
		     pathname = 'CfbRfirstcontol'
		     break
		    }
		    const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
		    window.open(newurl.href,'_blank')
		   }
		  },
		         // 双击事件
		    Cclick(num,mark,node,name){
		      // 组装数据
		      clearTimeout(this.grouptime);
		      let spojobj = {
		        num:num,
		        mark:mark,
		        node:node,
		        name:name
		      }
		      this.spotArr.push(spojobj)
		      // 数组去重
		      let deWeightThree = () => {
		          let map = new Map();
		          for (let item of this.spotArr) {
		              if (!map.has(item.name)) {
		                 this.$message.closeAll()
		                  map.set(item.name, item);
		                 this.$message.success('已添加');
		              }else{
		                this.$message.closeAll()
		                this.$message.error('已添加到变量池里，切勿重复添加');
		              }
		          }
		       
		          return [...map.values()];
		      }
		      this.spotArr = deWeightThree();
		      localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
		    },
		       isshowfase(){
		       this.isComShow = false
		     },
		    closeCompon(){
		
		      this.$emit('sendStatus', 'CfbCommonSetting',false)
		    },
		    isClose(val,val1){
		      switch(val){
		        case 'Historical':
		          return this.isHshow = val1
		      }
		    },
		    toIpt(data,name,historyname,node){
		
		      this.$refs.inputVal.open(data,name,historyname,node)
		    },
		    toCompon(key,name,name2,name3,titname){
		   this.isIndex = key
		   this.historyname = name
		   this.node = name2
		   this.Lkname = name3
		      clearTimeout(this.grouptime);
		      this.grouptime=setTimeout(() =>{
		          switch(key){
		      case 0:
				  console.log(1)
		          return this.isMshow = true,this.Manualname = name,this.Manualnode = name2,this.ManualAname = name3,this.Manualtitname = titname
		        case 1 :
		          return this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = name2,this.Firstcontroltitname = titname 
		        case 2:
		          return  this.isHshow = true
		        case 4:
		    return  this.isRshow = true,this.Rsfname = name,this.Rsfnode = name2,this.Rsftitname = titname
		   }
		      },300);    
		  },
		    } 
		}
	</script>

<style lang="scss" scoped>
	#CfbCommonSetting{
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;
		.title-hang {
		  width: 53vw;
		  height: 5vh;
		  font-family: MicrosoftYaHei;
		  font-size: 2vh;
		  font-weight: normal;
		  font-stretch: normal;
		  line-height: 2vh;
		  letter-spacing: 0vh;
		  margin-left: 15vw;
		}
		.title{
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top:1vh;
			text-align: center;
			margin-left: 1.5vw;
		} 
		.icon{
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 15vw;
			margin-top: 1.8vh;
		}
		.main{
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;
			.main_title{
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}
		.context{
			width: 50vw;
			height: 46vh;
			margin-top: 4vh;
			margin-left: 1.5vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;
			.button{
				width: 7.3vw;
				height: 2.6vh;
				background-color: #4bd2ff;
				text-align: center;
				.button_word{
					font-size: 1.5vh;
					line-height: 1.9vh;
					color: #d5fffe;
				}
			}
			.table{
				width: 50vw;
				height: 25.6vh;
				border:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				margin-top: 0.4vw;
				.left{
					border-right:1px solid rgba(0,228,255,.2);
					box-sizing: border-box;
				}
				.right{
					border-left:1px solid rgba(0,228,255,.2);
					box-sizing: border-box;
				}
				.bottom{
					border-bottom:1px solid rgba(0,228,255,.2);
					box-sizing: border-box;
				}
				.one{
					width: 16.6vw;
					font-size: 2.2vh;
					line-height: 6.3vh;
					.column1{
						margin-left: 2.9vw;
						width: 6vw;
						color: #8aeaff;
					}
					.column2{
						width: 5vw;
						text-align: center;
						color: #00ffb4;
						cursor: pointer;
					}
					.column3{
						width: 5vw;
						text-align: center;
						color: #fe1424;
					}
				}
				.two{
					width: 16.7vw;
					font-size: 2.2vh;
					line-height: 6.3vh;
					.column1{
						margin-left: 2.9vw;
						width: 6vw;
						color: #8aeaff;
					}
					.column2{
						width: 5vw;
						text-align: center;
						color: #00ffb4;
						cursor: pointer;
					}
				}
				.three{
					width: 16.6vw;
					font-size: 2.2vh;
					line-height: 6.3vh;
					.column1{
						margin-left: 1.9vw;
						width: 8vw;
						color: #8aeaff;
					}
					.column2{
						width: 5vw;
						text-align: center;
						color: #00ffb4;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
