<template>
	<div class="all drag" v-draw id="CfbOnlineService">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_客服系统</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
			
		<div class="company">
			<div class="company_title">
				和隆优化客户服务系统
			</div>
		</div>
		<div class="content">
			<div class="message">和隆信息</div>
			<div class="company_messages_total flex">
				<div class="company_messages_left">
					<div class="flex">
						<div class="words">客户服务热线：</div>
						<div class="num">400-8811-519</div>
					</div>
					<div class="flex">
						<div class="words">本项目技术支持热线：</div>
						<div class="num">18001170000</div>
					</div>
					<div class="flex">
						<div class="words">项目签订时间：</div>
						<div class="num">2020/7/21</div>
					</div>
					<div class="flex">
						<div class="words">项目开始实施时间：</div>
						<div class="num">2021/7/21</div>
					</div>
					<div class="flex">
						<div class="words">项目验收时间：</div>
						<div class="num">2021/8/21</div>
					</div>
				</div>
				<div class="company_messages_right">
					<div class="location">
						<div class="flex">
							<div class="words">项目经理电话：</div>
							<div class="num">某某&nbsp;&nbsp;18001170000</div>
						</div>
						<div class="flex">
							<div class="words">项目验收时间：</div>
							<div class="num">2021/8/21</div>
						</div>
					</div>
				</div>
			</div>
			<div class="message">客户信息</div>
			<div class="customer_messages_total flex">
				<div class="customer_messages_left">
					<div class="boiler_info">
						<div class="boiler_info_word">锅炉信息</div>
					</div>
					<div class="specific_info">
						<div class="flex">
							<div class="left_words">锅炉型号：</div>
							<div class="left_num">DG483/13.7-1</div>
						</div>
						<div class="flex">
							<div class="left_words">高炉炉容：</div>
							<div class="left_num">800m³</div>
						</div>
						<div class="flex">
							<div class="left_words">减温器：</div>
							<div class="left_num">单级</div>
						</div>
						<div class="flex">
							<div class="left_words">上煤：</div>
							<div class="left_num">3路皮带秤</div>
						</div>
						<div class="flex">
							<div class="left_words">返料器：</div>
							<div class="left_num">高温返料</div>
						</div>
						<div class="flex">
							<div class="left_words">一次风：</div>
							<div class="left_num">挡板控制</div>
						</div>
						<div class="flex">
							<div class="left_words">二次风：</div>
							<div class="left_num">挡板控制</div>
						</div>
						<div class="flex">
							<div class="left_words">上位软件：</div>
							<div class="left_num">Wincc6.0&nbsp;SP3</div>
						</div>
						<div class="flex">
							<div class="left_words">下位软件：</div>
							<div class="left_num">STEP7</div>
						</div>
						<div class="flex">
							<div class="left_words">制造单位：</div>
							<div class="left_num">济南锅炉</div>
						</div>
						<div class="flex">
							<div class="left_words">投产日期：</div>
							<div class="left_num">2021.12.31</div>
						</div>
					</div>
					
				</div>
				<div class="customer_messages_right">
					<div class="person_info">
						<div class="person_info_word">项目相关负责人信息</div>
					</div>
					<div class="right_up">
						<div class="flex">
							<div class="right-word">项目总负责人：</div>
							<div class="right_num">张三，18001179000；总公司环保处处长负责项目协调和关键时间节点把控；</div>
						</div>
						<div class="flex">
							<div class="right-word">工艺总负责人：</div>
							<div class="right_num">李四，18001179000；炼铁厂电仪科科长负责项目工艺流程和调试把控；</div>
						</div>
						<div class="flex">
							<div class="right-word">自动化负责人：</div>
							<div class="right_num">王五，18001179000；炼铁厂自动化处长负责项目DCS程序修改，通讯对接等；</div>
						</div>
						
					</div>
					<div class="right_down flex">
						<div class="right_down_left">
							<div class="flex">
								<div class="right-word1">1#控制室电弧：</div>
								<div class="right_num1">010-82926605</div>
							</div>
							<div class="flex">
								<div class="right-word1">大班长：</div>
								<div class="right_num1">18001179000</div>
							</div>
							<div class="flex">
								<div class="right-word1">甲班：</div>
								<div class="right_num1">18001179000</div>
							</div>
						</div>
						<div class="right_down_right">
							<div class="flex">
								<div class="right-word2">乙班：</div>
								<div class="right_num2">18001179000</div>
							</div>
							<div class="flex">
								<div class="right-word2">丙班：</div>
								<div class="right_num2">18001179000</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: "index",
  components: {

  },
     props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
  data: () => {
    return {
      flag: 0,

    };
  },
   watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
        this.$bus.$on("sendMsg", (msg,msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
    });
  },
  computed: {},
  mounted() {},
  methods: {

    closeCompon() {
      this.$emit("sendStatus", "Cfbserve", false);
    },
    isClose(){
     this.flag = 2,this.$bus.$emit("footersendMsg", 2)
    },

  },
};
</script>


<style lang="scss" scoped>
	.all{
		width: 36vw;
		height: 60vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		margin-left: 32vw;
		margin-top: 16vh;
		.title-hang {
		  width: 36vw;
		  height: 5vh;
		  font-family: MicrosoftYaHei;
		  font-size: 2vh;
		  font-weight: normal;
		  font-stretch: normal;
		  line-height: 2vh;
		  letter-spacing: 0vh;
		}
		.title{
			width: 30vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top:1vh;
			text-align: center;
			margin-left: 3vw;
		} 
		.icon{
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-top: 1.5vh;
		}
		.company{
			width: 36vw;
			height: 2vh;
			margin-top: 3vh;
			.company_title{
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}
		.content{
			margin-top: 2vh;
			margin-left: 1vw;
			width: 34vw;
			height: 46vh;
			border:1px solid #00e4ff;
			box-sizing: border-box;
			.message{
				height: 4vh;
				text-align: center;
				font-family: PingFang-SC-Regular;
				font-size: 2vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 4vh;
				letter-spacing: 0vh;
				color: #d5fffe;
				border-bottom:1px solid #00e4ff;
				box-sizing: border-box;
			}
			.company_messages_total{
				height: 11vh;
				border-bottom: 1px solid #00e4ff;
				font-family: PingFang-SC-Regular;
				font-size: 1vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;
				.company_messages_left{
					width: 17vw;
					height: 11vh;
					border-right:1px solid #00e4ff;
					.words{
						width: 10vw;
						text-align: right;
						color: #8aeaff;
					}
					.num{
						margin-left: 0.5vw;
						width: 6.5vw;
						color: #ffffff;
					}
				}
				.company_messages_right{
					font-family: PingFang-SC-Regular;
					font-size: 1vh;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2vh;
					letter-spacing: 0vh;
					.location{
						margin-top: 2vh;
						.words{
							width: 7vw;
							text-align: right;
							color: #8aeaff;
						}
						.num{
							margin-left: 0.5vw;
							width: 6.5vw;
							color: #ffffff;
						}
					}
				}
			}
			.customer_messages_total{
				font-family: PingFang-SC-Regular;
				font-size: 1vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 1vw;
				letter-spacing: 0vh;
				.customer_messages_left{
					width: 14vw;
					height: 26.8vh;
					border-right:1px solid #00e4ff;
					.boiler_info{
						height: 1vh;
						.boiler_info_word{
							color: #d5fffe;
							text-align: center;
							margin-top: 2vh;
						}
					}
					.specific_info{
						margin-top: 1vh;
						.left_words{
							width: 6vw;
							color: #8aeaff;
							text-align: right;
						}
						.left_num{
							width: 8vw;
							color: #ffffff;
						}
					}
				}
				.customer_messages_right{
					.person_info{
						height: 1vh;
						.person_info_word{
							color: #d5fffe;
							text-align: center;
							margin-top: 2vh;
						}
					}
					.right_up{
						margin-top: 1.5vh;
						margin-left: 0vw;
						.right-word{
							width: 6vw;
							color: #8aeaff;
							text-align: right;
						}
						.right_num{
							width: 13vw;
							color: #ffffff;
						}
					}
					.right_down{
						margin-top: 0.5vw;
						.right_down_left{
							.right-word1{
								width: 6vw;
								color: #8aeaff;
								text-align: right;
							}
							.right_num1{
								color: #ffffff;
							}
						}
						.right_down_right{
							margin-left: 0.5vw;
							.right-word2{
								width: 3vw;
								color: #8aeaff;
								text-align: right;
							}
							.right_num2{
								color: #ffffff;
							}
						}
					}
				}
				
			}
		}
		
		
		
	}
	
</style>
